/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.ant-notification {
	z-index: 9999;

	.anticon {
		color: $white !important;
	}

	.ant-notification-notice-message,
	.ant-notification-notice-description {
		color: $white !important;
	}

	.ant-notification-notice-message {
		text-transform: capitalize;
		font-weight: 700;
	}

	.ant-notification-notice-success {
		background-color: $success;
	}

	.ant-notification-notice-info {
		background-color: $info;
	}

	.ant-notification-notice-warning {
		background-color: $warning;
	}

	.ant-notification-notice-error {
		background-color: $danger;
	}
}

.modal-backdrop {
	&.fade {
		display: none;
	}
}

.modal-open {
	overflow: hidden;

	.modal-backdrop {
		display: block;
		opacity: var(--vz-backdrop-opacity);
	}

	.modal {
		&.show {
			display: block;
		}
	}
}

.react-confirm-alert-overlay {
	z-index: 9999;
}

.page-link {
	&.active {
		pointer-events: none;
	}
}

.list-group-settings {
	.list-group-item:not(.active) {
		background-color: transparent !important;
	}
}

.selector {
	select {
		appearance: menulist !important;
		padding: 0.35rem 0.4rem;
		width: 50px;
	}
}

.is-invalid {
	div[class$='control'] {
		border: 1px solid $danger !important;
	}

	.ck-editor {
		border: 1px solid $danger !important;
	}
}

.text-underline {
	text-decoration: underline;
}

.alert-link {
	&:hover {
		color: var(--vz-alert-link-color);
		text-decoration: underline;
	}
}

.nav-tabs-custom-2 {
	border: none !important;

	.nav-item {
		position: relative;

		.nav-link {
			border: none;
			font-weight: $font-weight-medium;

			&.active {
				color: var(--#{$prefix}primary);

				&:after {
					transform: scale(1);
				}
			}
		}
	}

	&.card-header-tabs {
		margin-top: -1rem;

		.nav-link {
			padding: $card-cap-padding-y $card-cap-padding-x;
		}
	}
}

.form-label {
	margin-bottom: 0.15rem;
}

.form-control {
	&.is-invalid {
		&:focus {
			box-shadow: none;
		}
	}

	&:focus {
		box-shadow: none;
	}
}

.canvas-background {
	background-color: var(--vz-body-bg);
}

.rpv-default-layout__sidebar-headers {
	display: none !important;
}

.profile-setting-img {
	height: 80px;
}

.cursor-default {
	cursor: default !important;
}

.accordion-button {
	&:focus {
		box-shadow: none !important;
	}
}

.set-permission-block {
	height: calc(100vh - 260px - (var(--vz-modal-padding) * 2) - 1.5rem);
	overflow-y: auto;
}

.radio-container {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	height: 36px;
	width: 36px;

	&.selected {
		background-color: #b3b6b9 !important;
	}

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}

	span {
		height: 18px;
		width: 18px;
		top: 9px;
		left: 9px;
	}
}

.dropzone {
	&.dz-clickable {
		cursor: pointer !important;
	}
}

.react-datepicker-popper {
	z-index: 9000 !important;
}

.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker__input-container {
	display: block !important;
}

.react-datepicker__view-calendar-icon {
	input {
		// padding: 6px 10px 5px 25px;
		padding: 0.5rem 0.9rem 0.5rem 28px;
	}
}
